import React from 'react';
import Navbar from './components/navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Pages from './components/Pages';
import { BrowserRouter } from 'react-router-dom';
import Nav_mobile from './components/navbar/Nav_mobile';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      {/* <Navbar /> */}
      <Nav_mobile />
        <Pages />
      </BrowserRouter>
    </div>
  );
}

export default App;
