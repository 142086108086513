import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Container, Nav, Form, FormControl, Button } from 'react-bootstrap';
import { routes } from './routes';
import "../styles/navbar-style.css";

function Nav_mobile() {

  const item = routes.map((item, index) => {
  return  <Nav.Link className='nav-linkas text-center' key={index} href={item.path}>{item.title}</Nav.Link>
  })

  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">AFI</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}>
          {expanded ? <i className="fa fa-times" aria-hidden="true"></i>: <i className="fa fa-bars" aria-hidden="true"></i>}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {item}
          </Nav>
          <Form className="d-flex">
            <FormControl type="search" placeholder="Search" className="mr-2" aria-label="Search" />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Nav_mobile;
