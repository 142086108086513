import React from 'react';
import * as Tiicons from "react-icons/ti";

export const routes = [
    // {
    //     title: 'News',
    //     path: '/news',
    //     icon: <Tiicons.TiHomeOutline />,
    //     cName: 'nav-text'
    // },
    {
        title: 'Solutions',
        path: '/solutions',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    },
    {
        title: 'About',
        path: '/about',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    },
    {
        title: 'Gallery',
        path: '/gallery',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    },
    {
        title: 'Contacts',
        path: '/contacts',
        email: 'emailas',
        tel: '+37000000',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    }
]