import React from 'react';
import { Routes, Route } from "react-router-dom";
import Solutions from './Solutions';
import Home from './Home';
import About from './About';
import Contacts from './Contacts';
import Control from './Control/Control';
import News from './News';
import Gallery from './Gallery';
function Pages() {
  return (
    <Routes>
        <Route index element={<Home />} />
        <Route path='/' element={<Home />} />
        <Route path='/solutions' element={<Solutions />} />
        <Route path='/about' element={<About />} />
        <Route path='/contacts' element={<Contacts />} />
        <Route path='/gallery' element={<Gallery />} />
        {/* <Route path='/control' element={<Control />} /> */}
        <Route path='/news' element={<News />} />
    </Routes>
  )
}

export default Pages
