import React, { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../styles/navbar-style.css";
import { Link } from "react-router-dom";
import { routes } from "./routes";

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	return (
		<div className="nav-container">
			<a href="/"> <img src="./images/nav-logo.svg" alt="" className="navbar-logo" /></a>
			<nav ref={navRef}>
				{routes.map((item, index) => {
					return (
						<Link key={index} to={item.path} className={item.cName} onClick={() => showNavbar()}>
							<div className="nav-item">
								{/* Jei norite pridėti ikoną, atkomentuokite šią eilutę ir įdėkite item.icon */}
								{/* {item.icon} */}
								<span className='nav-title'>{item.title}</span>
							</div>
						</Link>
					);
				})}
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</div>
	);
}

export default Navbar;