import React from 'react'
import ComingSoon from './ComingSoon/ComingSoon'

const Gallery = () => {
  return (
    <div>
      <ComingSoon launchDate="2024-12-31T23:59:59" />
    </div>
  )
}

export default Gallery